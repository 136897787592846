exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-activities-index-js": () => import("./../../../src/pages/activities/index.js" /* webpackChunkName: "component---src-pages-activities-index-js" */),
  "component---src-pages-archives-js": () => import("./../../../src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-restaurant-js": () => import("./../../../src/pages/restaurant.js" /* webpackChunkName: "component---src-pages-restaurant-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-surrounding-js": () => import("./../../../src/pages/surrounding.js" /* webpackChunkName: "component---src-pages-surrounding-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-templates-archive-page-js": () => import("./../../../src/templates/archivePage.js" /* webpackChunkName: "component---src-templates-archive-page-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

